import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Layout from '../components/Layout'
import PageBannerImgBackground from '../components/PageBannerImgBackground/pageBannerImgBackground'
import '../css/global.scss'


export const CareerItemTemplate = ({
  pageName,
  title,
  content,
}) => {




  return (
    <div id="page">

      
    <PageBannerImgBackground 
    imgPath='/img/capabilities/Capabilities_Banner.png'
    title={title}
    description=''
    />
	
	<div className="til-section">
		<div className="work-container">

    <div className="row" style={{width: '90%', margin: '0px auto' }}>
                <div className="col-md-4 col-sm-3 col-xs-12 work-xs-position">
                  <div className="col-md-4 col-sm-0"></div>
                  <div className="col-md-8 col-sm-12 work-navigation" style={{margin: '20px'}}>
                    <div className="btn-group pull-left" role="group" aria-label="..." >
  
                      <a href="/careers" className="work-links" >
                        <button className='btn-default' style={{width: '240px'}}>
                        <ChevronLeftIcon style={{fontSize: '30px'}}/>
                        Back to Our Careers
                      </button></a>
                    </div>						
                  </div>                        
                </div>
			
					<div className="col-md-8 col-sm-9 col-xs-12 careers-body" style={{color: ' rgb(114, 114, 114)'}}>
            <h2 style={{color: 'black'}}>{title}</h2>
             <div style={{color: ' rgb(114, 114, 114)'}} dangerouslySetInnerHTML={{ __html: content }} />     
							<p style={{paddingTop: '50px', fontWeight: 'bold'}}>To apply, please send your resume and cover letter to jobs@theinclab.com</p>
				</div>
				
        </div>
      </div>
      


        </div>
    </div>
  )
}

CareerItemTemplate.propTypes = {
  pageName: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  // body: PropTypes.string,
}

const Career = ({ data }) => {
  const { markdownRemark: post } = data
  const { frontmatter } = data.markdownRemark

  return (
    <Layout
    title={frontmatter.title}
    >
      <CareerItemTemplate
        title={frontmatter.title}
        pageName={frontmatter.pageName}
        content={post.html}
      />
    </Layout>
  )
}

Career.propTypes = {
    data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Career

export const pageQuery = graphql`
  query CareerPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        pageName
        title
      }
    }
  }
`
